<template>
  <section class="section section_votes">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="title title_dark title_second title_svg title_svg-show text-uppercase">Материалы конференции
          </h2>
        </div>
      </div>
      <div class="row pt-4 d-flex flex-column-reverse flex-lg-row">
        <div class="program" v-for="prog in getProgram" :key="prog.id">
          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="programs__header">
                <div class="program__items" v-for="item in prog.sections" :key="item.id">
                  <div class="program__head" @click="changeActive(item.id)"
                       :class="item.id === activeProgram ? 'active' : ''">
                    <div class="container">
                      <div class="row">
                        <div class="col-12">
                            <span class="program__area">
                              {{ item.name }}
                            </span>
                          <span class="program__themes">
                              | <span v-html="item.theme"></span>
                            </span>
                        </div>
                        <!--                          <div class="col-2 text-end d-flex align-items-center justify-content-end" v-if="item.id !== activeProgram">-->
                        <!--                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"-->
                        <!--                                 xmlns="http://www.w3.org/2000/svg">-->
                        <!--                              <path opacity="0.7"-->
                        <!--                                    d="M16 8.71198H8.88802V16H7.11198V8.71198H0V7.28802H7.11198V0H8.88802V7.28802H16V8.71198Z"-->
                        <!--                                    fill="#0079C1"/>-->
                        <!--                            </svg>-->
                        <!--                          </div>-->
                      </div>
                    </div>
                  </div>
                  <div class="program__mobile d-lg-none">
                    <vue-slide-toggle :open="item.id === activeProgram" :duration="500" class="program__content">
                      <div class="container">
                        <div class="row">
                          <div class="col-12">
                            <div class="program__item" v-for="val in getProgramWithPresentation(item.program)"
                                 :key="val.id">
                              <div class="program__h" >
                                <div class="row">
                                  <div class="col-12 col-md-4 col-lg-3">
                                    <div class="program__time">{{ val.time }}</div>
                                  </div>
                                  <div class="col-12 col-md-8 col-lg-8">
                                    <div class="program__theme" v-html="val.name">{{ val.name }}</div>
                                  </div>
                                </div>
                              </div>
                              <program-content :program="val" is-material />
                            </div>

                          </div>
                        </div>
                      </div>
                    </vue-slide-toggle>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-8 d-none d-lg-block">
              <div class="program__wrapper">
                <div class="program__wrap" v-for="item in prog.sections" :key="item.id">
                  <div class="program__content" v-if="item.id === activeProgram">
                    <div class="program__item" v-for="val in getProgramWithPresentation(item.program)" :key="val.id">
                      <div class="program__h">
                        <div class="row">
                          <div class="col-3">
                            <div class="program__time">{{ val.time }}</div>
                          </div>
                          <div class="col-6">
                            <div class="program__theme" v-html="val.name"> {{ val.name }}</div>
                          </div>
                          <div class="col-3 text-end">
                            <a :href="val.presentation" target="_blank" class="button button_blue text-center">
                              <span style="font-size: 14px;">Скачать презентацию</span>
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                   style="margin-left: 5px"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M19.8975 7.8525L14.6475 2.6025C14.5421 2.49698 14.3991 2.43763 14.25 2.4375H5.25C4.9019 2.4375 4.56806 2.57578 4.32192 2.82192C4.07578 3.06806 3.9375 3.4019 3.9375 3.75V20.25C3.9375 20.5981 4.07578 20.9319 4.32192 21.1781C4.56806 21.4242 4.9019 21.5625 5.25 21.5625H18.75C19.0981 21.5625 19.4319 21.4242 19.6781 21.1781C19.9242 20.9319 20.0625 20.5981 20.0625 20.25V8.25C20.0624 8.10087 20.003 7.95789 19.8975 7.8525ZM14.8125 4.3575L18.1425 7.6875H14.8125V4.3575ZM18.75 20.4375H5.25C5.20027 20.4375 5.15258 20.4177 5.11742 20.3826C5.08225 20.3474 5.0625 20.2997 5.0625 20.25V3.75C5.0625 3.70027 5.08225 3.65258 5.11742 3.61742C5.15258 3.58225 5.20027 3.5625 5.25 3.5625H13.6875V8.25C13.6875 8.39918 13.7468 8.54226 13.8523 8.64775C13.9577 8.75324 14.1008 8.8125 14.25 8.8125H18.9375V20.25C18.9375 20.2997 18.9177 20.3474 18.8826 20.3826C18.8474 20.4177 18.7997 20.4375 18.75 20.4375ZM14.6475 14.6025C14.7528 14.708 14.812 14.8509 14.812 15C14.812 15.1491 14.7528 15.292 14.6475 15.3975L12.3975 17.6475C12.292 17.7528 12.1491 17.812 12 17.812C11.8509 17.812 11.708 17.7528 11.6025 17.6475L9.3525 15.3975C9.25314 15.2909 9.19905 15.1498 9.20162 15.0041C9.20419 14.8584 9.26322 14.7193 9.36628 14.6163C9.46934 14.5132 9.60838 14.4542 9.75411 14.4516C9.89983 14.449 10.0409 14.5031 10.1475 14.6025L11.4375 15.8916V11.25C11.4375 11.1008 11.4968 10.9577 11.6023 10.8523C11.7077 10.7468 11.8508 10.6875 12 10.6875C12.1492 10.6875 12.2923 10.7468 12.3977 10.8523C12.5032 10.9577 12.5625 11.1008 12.5625 11.25V15.8916L13.8525 14.6025C13.958 14.4972 14.1009 14.438 14.25 14.438C14.3991 14.438 14.542 14.4972 14.6475 14.6025Z"
                                    fill="white"/>
                              </svg>

                            </a>
                          </div>
                        </div>
                      </div>
                      <program-content :program="val"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import {find, filter} from "lodash";
import ProgramContent from "@/components/components/ProgramContent.vue";

export default {
  name: "ConferenceMaterials",
  data: () => ({
    activeProgram: 1,
    openPrograms: [],
  }),
  components: {
    ProgramContent
  },
  computed: {
    getProgram() {
      return this.$store.getters.getProgram
    },
    getSection() {
      const day = this.getProgram[0]
      const section = find(day.sections, (section) => section.id === this.activeProgram)
      console.log(section)
      return section
      // return this.getProgram[0]?.sections[this.activeProgram]?.program || []
    }
  },
  watch: {
    activeProgram() {
      this.openPrograms = []
    }
  },
  methods: {
    getProgramWithPresentation(program) {
      return filter(program, (item) => item.presentation)
    },
    changeActive(id) {
      if (window.screen.width < 992) {
        if (this.activeProgram === id) {
          this.activeProgram = null
        } else {
          this.activeProgram = id
        }
      } else {
        this.activeProgram = id

      }
    },
    toggleItem(id) {
      if (this.openPrograms.indexOf(id) !== -1) {
        this.openPrograms.splice(this.openPrograms.indexOf(id), 1)
      } else {
        this.openPrograms.push(id)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.section_votes {
  padding: rem(30) 0;
}

.program {
  padding-top: rem(30);

  &__head {
    background: #E7E7E7;
    border: 1px solid #E4F5FF;
    border-radius: 10px;
    padding: 15px 0;
    font-size: 16px;
    font-weight: 300;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: rem(5);
    min-height: 65px;
    @include media(992) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.active {
      background: #0079C1;
      color: #fff;
    }

  }

  &__area {
    //text-transform: lowercase;
    font-weight: 500;
    font-size: 16px;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__theme {
    //text-transform: lowercase;
    font-size: 18px;
    font-weight: 300;

    &:first-letter {
      text-transform: uppercase;
    }

    &::v-deep {
      p {
        margin-bottom: 0;
      }
    }

  }

  &__time {
    color: #00476D;
    font-size: rem(21);
    font-weight: 600;
  }

  &__text {

  }

  &__item {
    border-bottom: 2px solid #ECECF1;

  }

  &__h {
    padding: rem(15) 0;
    cursor: pointer;
    position: relative;
    @include media(768) {
      svg {
        position: absolute;
        top: 20px;
        right: 10px;
      }

    }
  }

  &__name {
    font-size: rem(18);
    font-weight: 300;
    color: #282938;
  }

  &__text {
    background: #F4F4F4;
    border-radius: 10px;
    padding: rem(10) 0;
    margin-bottom: rem(10);
  }

  &__theme {
    &::v-deep {
      ul {
        padding: 0;
        margin: 0;
      }

      @include media(768) {
        padding: 0 0px;
      }
    }
  }


}

</style>